import React, { useEffect, useState } from 'react';
import { NounAnswers, useAppState } from '../context/AppStateContext';
import AnswerList from '../components/AnswerList';
import { Card } from '../components/Card';
import CategoryButton from '../components/CategoryButton';
import { PanInfo, motion, useDragControls, useMotionValue, useTransform } from 'framer-motion';

const GROWTH_FACTOR = 1.05;

const HomePage: React.FC = () => {
    const { currentNoun, updateNextNoun, assignValueToNoun } = useAppState();

    const [maxBounds, setMaxBounds] = useState(150); // Default value, will be updated in useEffect

    const updateMaxBounds = () => {
        const newBounds = window.innerWidth * 0.1;
        setMaxBounds(newBounds);
    };

    useEffect(() => {
        updateMaxBounds();
        window.addEventListener('resize', updateMaxBounds);

        // Clean up the event listener when the component unmounts
        return () => window.removeEventListener('resize', updateMaxBounds);
    }, []);

    useEffect(() => {
        updateMaxBounds();
        }, []
    )

    const x = useMotionValue(0);
    
    // Button scale derived values.
    const scaleBouba = useTransform(x, [-maxBounds, 0], [GROWTH_FACTOR, 1]);
    const scaleKiki = useTransform(x, [0, maxBounds], [1, GROWTH_FACTOR]);

    const onDragEnd = (info: PanInfo) => {
        if (info.offset.x >= maxBounds) {
            handleNextClick(NounAnswers.Kiki)
        } else if (info.offset.x <= -maxBounds) {
            handleNextClick(NounAnswers.Bouba)
        }
    }

    const dragControls = useDragControls();
    
    const handleNextClick = (type: NounAnswers) => {
        assignValueToNoun(currentNoun, type);
        updateNextNoun();
    }

    return (
        <>
        <div className="mt-5 mb-10">
            <h1 className="text-5xl font-semibold">Bouba or Kiki?</h1>
            <p className="text-2xl font-400 mt-1">Is that thing a Bouba or a Kiki?</p>
            <p className="text-sm font-light mt-2 max-w-prose"><a className="underline underline-offset-2 text-themecolor-primary" href="https://en.wikipedia.org/wiki/Bouba/kiki_effect">The Bouba/Kiki effect</a> refers to a relationship where people tend to associate sharp shapes with the sound 'kiki', and round shapes with 'bouba'. What if Bouba/Kiki applied to more than just two shapes...?</p>
        </div>
        <div className="grid grid-cols-3 md:max-2xl:gap-10 gap-5 mb-5">
            <motion.div style={{ scale: scaleBouba, zIndex: 1 }}>
                <CategoryButton iconSrc="/bouba.svg" buttonText="Bouba" onClick={() => handleNextClick(NounAnswers.Bouba)} />
            </motion.div>
            <motion.div drag="x" dragConstraints={{ left: -maxBounds, right: maxBounds }} whileTap={{ scale: .85 }} style={{ x, zIndex: 2}} transition={{ type: 'spring' }} dragSnapToOrigin={true} onDragEnd={(e, info: PanInfo) => onDragEnd(info)} dragControls={dragControls}>
                <Card noun={currentNoun} />
            </motion.div>
            <motion.div style={{ scale: scaleKiki, zIndex: 1 }}>
                <CategoryButton iconSrc="/kiki.svg" buttonText="Kiki" onClick={() => handleNextClick(NounAnswers.Kiki)} />
            </motion.div>
        </div>
        <AnswerList />
        </>
    )
}

export default HomePage;