import { faGear, faSquareUpRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
    children?: React.ReactNode;
    onSettingsClicked: () => void;
};

export const PageContainer: React.FC<Props> = ({ children, onSettingsClicked }) => {
    return (
        <div className="md:max-2xl:px-20 px-5 max-w-7xl mx-auto my-10">
            <div className="flex justify-between">
                <a className="text-themecolor-primary font-700" href="https://polner.dev/">polner.dev <FontAwesomeIcon icon={faSquareUpRight} /></a>
                <FontAwesomeIcon size="lg" icon={faGear} onClick={onSettingsClicked} />
            </div>
            {children}
        </div>
    )
}

export default PageContainer;