import React from 'react';

type CategoryButtonProps = {
  onClick: () => void;
  iconSrc: string;
  buttonText: string;
  children?: React.ReactNode;
}

const CategoryButton: React.FC<CategoryButtonProps> = ({ onClick, iconSrc, buttonText  }) => {
  return (
    <button
      className={`bg-themecolor-primary active:scale-95 p-2 rounded-xl text-white transition-all duration-100	ease-in-out min-w-full min-h-full`}
      onClick={onClick}
    >
        <div className="grid place-items-center py-3 gap-4 select-none">
            <img className="w-3/12 my-5 select-none" src={iconSrc} alt=""/>
            <p className="font-medium text-xl">{buttonText}</p>
        </div>
    </button>
  );
};

export default CategoryButton;