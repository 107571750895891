import React, { useEffect, useState } from 'react';
import { AnswerMap, AppStateContext, NounAnswers } from '../context/AppStateContext';
import { DEFAULT_NOUNS, NounProvider } from '../providers/NounProvider';
import NounPackProvider from '../providers/NounPackProvider';

type Props = {
    children?: React.ReactNode
};

export const AppStateProvider: React.FC<Props> = ({ children }) => {
    const [answers, setAnswers] = useState<AnswerMap>({});
    const [nounProvider] = useState(() => new NounProvider(DEFAULT_NOUNS));
    const [currentNoun, setCurrentNoun] = useState<string>(nounProvider.getCurrentNoun());

    useEffect(() => {
        const loadNouns = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const packName = searchParams.get('nounPack')
            if (!packName) { return; }

            const nouns = await NounPackProvider.getNounPack(packName);

            if (!nouns || nouns.length === 0) { return; }

            nounProvider.updateNouns(nouns)
            setCurrentNoun(nounProvider.getCurrentNoun());
        }

        loadNouns();
    }, [nounProvider])

    const getNextNoun = (): string => {
        return nounProvider.getNextNoun();
    }

    const updateNextNoun = () => {
        nounProvider.updateNextNoun();
        setCurrentNoun(nounProvider.getCurrentNoun());
    }

    const assignValueToNoun = (noun: string, value: NounAnswers) => {
        setAnswers((prevDict: AnswerMap) => ({
            ...prevDict,
            [noun]: value
          }));
    }

    const updateNouns = (nouns: string[]) => {
        nounProvider.updateNouns(nouns);
        // TODO: Clear previous answers
        setCurrentNoun(nounProvider.getCurrentNoun());
    }

    return (
        <AppStateContext.Provider value={{ currentNoun, answers, updateNextNoun, getNextNoun, assignValueToNoun, updateNouns }}>
            {children}
        </AppStateContext.Provider>
    )
}