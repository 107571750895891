import React from 'react';

import './App.css';
import { AppStateProvider } from './app/AppStateProvider';
import HomePage from './pages/Home';
import PageContainer from './components/PageContainer';
import SettingsModal from './components/SettingsModal';

function App() {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  
  const onSettingsClicked = () => {
    setModalIsOpen(true);
  }

  return (
    <AppStateProvider>
      <PageContainer onSettingsClicked={onSettingsClicked}>
        <HomePage />
        <SettingsModal isModalOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      </PageContainer>
    </AppStateProvider>
  );
}

export default App;
