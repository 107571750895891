import React from 'react';

export type CardProps = {
    noun: string;
}

export const Card: React.FC<CardProps> = ({ noun }) => {
    return (
        <div className="px-6 py-6 shadow-lg rounded-xl flex justify-center items-center h-full bg-white z-10">
            <p className="font-medium text-xl text-center">{noun}</p>
        </div>
    )

}