export const DEFAULT_NOUNS = [
    "Drake", "Simone Biles", "Billie Eilish", "Lionel Messi",
    "Adele", "Usain Bolt", "Lizzo", "Cristiano Ronaldo",
    "Ed Sheeran", "Naomi Osaka", "Harry Styles", "Megan Rapinoe",
    "Shawn Mendes", "Venus Williams", "The Weeknd", "Michael Phelps",
    "Selena Gomez", "Novak Djokovic", "Dua Lipa", "Tom Holland",
    "Apple", "Banana", "Cherry", "Date",
    "Elderberry", "Fig", "Grapefruit", "Honeydew",
    "Jackfruit", "Kumquat", "Lemon", "Melon",
    "Nectarine", "Orange", "Papaya", "Quince",
    "Rambutan", "Starfruit", "Tangerine", "Ugli fruit",
    "Apple Pie", "Brownies", "Cupcakes", "Doughnuts",
    "Eclairs", "Fondant", "Gelato", "Honey cake",
    "Ice Cream Sandwich", "Jelly", "Key Lime Pie", "Lava Cake",
    "Mousse", "Napoleon", "Oatmeal Cookies", "Pudding",
    "Queen of Puddings", "Red Velvet Cake", "Souffle", "Trifle",
    "Zendaya", "Anthony Joshua", "Katy Perry", "Roger Federer",
    "Camila Cabello", "Kevin Hart", "Lady Gaga", "Shaquille O'Neal",
    "Miley Cyrus", "Conor McGregor", "Nicki Minaj", "Eliud Kipchoge",
    "Lychee", "Mandarin", "Nance", "Olive",
    "Persimmon", "Quince", "Raspberry", "Satsuma",
    "Tamarillo", "Uva", "Voavanga", "Wolfberry",
    "Xigua", "Yellow Watermelon", "Zucchini",
    "Upside-down Cake", "Vol-au-vent", "Whoopie Pie", "Xmas Cookies",
    "Yogurt Parfait", "Zabaglione", "Affogato", "Baklava",
    "Cannoli", "Daifuku", "Empanada", "Flan",
    "Gingersnap", "Halva", "Ice Cream Cake", "Jambalaya",
    "Kulfi", "Lemon Bars", "Marzipan", "Nougat",
    "Chris Hemsworth", "Alicia Keys", "Bruno Mars", "Charlize Theron",
    "Dave Chappelle", "Ellen DeGeneres", "Frank Ocean", "Greta Thunberg",
    "Hugh Jackman", "Idris Elba", "Jennifer Lawrence", "Kanye West",
    "Açaí", "Blood Orange", "Clementine", "Durian",
    "Feijoa", "Guava", "Huckleberry", "Ilama",
    "Jujube", "Kiwi Berry", "Loganberry", "Mulberry",
    "Opera Cake", "Pancakes", "Qottab", "Rice Pudding",
    "S'mores", "Tarte Tatin", "Unbaked Cake", "Victoria Sponge Cake",
    "Waffles", "Yule Log", "Zepolle"
]

interface INounProvider {
    getCurrentNoun(): string;
    getNextNoun(): string;
    updateNextNoun(): void;
    updateNouns(nouns: string[]): void;
}

// Simple Noun Provider that iterates over a list of pre-defined nouns.
export class NounProvider implements INounProvider {
    private currentIndex: number = 0;
    private nouns: string[];

    private defaultNouns: string[];

    constructor(nouns: string[]) {
        this.nouns = nouns;
        this.defaultNouns = nouns;
    }

    public getCurrentNoun(): string {
        return this.nouns[this.currentIndex];
    }

    public getNextNoun(): string {
        if ((this.currentIndex + 1) >= this.nouns.length) {
            return "🤷";
        }
        return this.nouns[this.currentIndex+1]
    }

    public updateNextNoun(): void {
        this.currentIndex++;    

        if (this.currentIndex >= this.nouns.length) {
            this.currentIndex = 0;
        }
    }

    public updateNouns(nouns: string[]): void {
        // Create a copy to ensure `this.nouns` persists even after `nouns` leaves scope.
        this.nouns = [...nouns];
        this.currentIndex = 0;
    }
}