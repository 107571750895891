
import Modal from 'react-modal';
import { useAppState } from '../context/AppStateContext';
import { useState } from 'react';
import { DEFAULT_NOUNS } from '../providers/NounProvider';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
    overlay: {zIndex: 1000}
};

type Props = {
    isModalOpen: boolean;
    setModalIsOpen: (value: boolean) => void;
};
const SettingsModal: React.FC<Props> = ({ isModalOpen, setModalIsOpen }) => {
    const { updateNouns } = useAppState();

    const [hasActiveEdit, setHasActiveEdit] = useState(false);
    const [input, setInput] = useState<string | undefined>("");

    const attemptToCloseModal = () => {
        if (hasActiveEdit) {
            console.log("Cannot close")
        } else {
            setModalIsOpen(false);
        }
    }

    const saveChanges = () => {
        if (input !== "" && input !== undefined) {
            updateNouns(parseInput(input));
        }
        setHasActiveEdit(false);
        setModalIsOpen(false);
    }

    const clearChanges = () => {
        setModalIsOpen(false);
    }

    const resetNouns = () => {
        updateNouns(DEFAULT_NOUNS);
        setModalIsOpen(false);
    }

    const parseInput = (input: string): string[] => {
        return input.split(",");
    }

    const onTextFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== "") {
            setHasActiveEdit(true);
            setInput(event.target.value);
        }
    }

    return (
        <Modal
            style={customStyles}
            isOpen={isModalOpen}
            onRequestClose={() => attemptToCloseModal()}>
                <h1 className="font-bold text-2xl mb-2">Settings</h1>
                <p className="mb-2 max-w-prose text-sm">Enter your own words to vote between Kiki and Bouba. Separate each term with a comma! Entering new words here will reset your current votes.</p>
                <input type="text" onChange={onTextFieldChanged} className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Apple, Pear, Blueberry" required />
                <div className="flex justify-between mt-4">
                    <div className="flex gap-2">
                        <button className="bg-themecolor-primary text-white py-2 px-3 rounded-full text-sm" onClick={saveChanges}>Save</button>
                        <button className="bg-red-500 text-white py-2 px-3 rounded-full text-sm" onClick={clearChanges}>Clear</button>
                    </div>
                    <button className="bg-red-500 text-white py-2 px-3 rounded-full text-sm" onClick={resetNouns}>Reset to Default</button>
                </div>
        </Modal>
    )
}

export default SettingsModal;