import React from 'react';
import { NounAnswers, useAppState } from '../context/AppStateContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const HomePage: React.FC = () => {
    const { answers } = useAppState();

    function getNounsByType(type: NounAnswers): string[] {
        return Object.entries(answers)
            .filter(([_, target]) => type === target)
            .map(([noun, _]) => noun);
    }

    function renderNounList(nouns: string[]) {
        return (
            <ul>
                {nouns.map(noun => (
                    <li key={noun}>{noun}</li>
                ))}
            </ul>
        );
    }

    const onExportClicked = async () => {
        let outputString = ""

        outputString += "--- Bouba:\n";
        outputString += getNounsByType(NounAnswers.Bouba).join("\n");
        outputString += "\n\n--- Kiki:\n";
        outputString += getNounsByType(NounAnswers.Kiki).join("\n");

        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(outputString);
        }
    }
    
    return (
        <div className="mt-3">
            <p className="text-2xl font-bold">Your answers so far...</p>
            <p className="text-gray-400 mt-1 text-sm cursor-pointer" onClick={onExportClicked}>Copy my answers <FontAwesomeIcon icon={faCopy} /></p>
            <div className="grid grid-cols-2 mt-2">
                <div>
                    <p className="text-2xl mb-2">Bouba</p>
                    {renderNounList(getNounsByType(NounAnswers.Bouba))}
                </div>
                <div>
                    <p className="text-2xl mb-2">Kiki</p>
                    {renderNounList(getNounsByType(NounAnswers.Kiki))}
                </div>
            </div>
        </div>
    )
}

export default HomePage;