import React from 'react';

export enum NounAnswers {
    Kiki,
    Bouba
}

export type AnswerMap = { [noun: string]: NounAnswers };

interface AppState {
    currentNoun: string;
    answers: AnswerMap;
    getNextNoun: () => string;
    updateNextNoun: () => void;
    assignValueToNoun: (noun: string, value: NounAnswers) => void;
    updateNouns: (nouns: string[]) => void;
}

export const AppStateContext = React.createContext<AppState | undefined>(undefined);

export const useAppState = () => {
    const context = React.useContext(AppStateContext);
    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }

    return context;
}